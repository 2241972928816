body {
   margin: 0;
   font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

html,
body,
#root {
   height: 100%;
}

@font-face {
   font-family: 'Ubuntu';
   src: url('../assets/fonts/Ubuntu-Regular.ttf');
}

@font-face {
   font-family: 'UbuntuBold';
   src: url('../assets/fonts/Ubuntu-Bold.ttf');
}

@font-face {
   font-family: 'UbuntuLight';
   src: url('../assets/fonts/Ubuntu-Light.ttf');
}